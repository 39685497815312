<template>
  <ul>
    <!-- <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    /> -->
    <ul class="navigation navigation-main">
      <li class="nav-item has-sub open sidebar-group-active">
        <a href="#" target="_self" class="d-flex align-items-center"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="14px"
            height="14px"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-server"
          >
            <rect x="2" y="2" width="20" height="8" rx="2" ry="2"></rect>
            <rect x="2" y="14" width="20" height="8" rx="2" ry="2"></rect>
            <line x1="6" y1="6" x2="6.01" y2="6"></line>
            <line x1="6" y1="18" x2="6.01" y2="18"></line></svg
          ><span class="menu-title text-truncate">UCS 관제정보</span
          ><!----></a
        >
        <ul class="menu-content collapse show" id="__BVID__66" style="">
          <li class="nav-item">
            <a
              href="#/ucs/servobnt"
              aria-current="page"
              class="d-flex align-items-center"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="14px"
                height="14px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-circle"
              >
                <circle cx="12" cy="12" r="10"></circle></svg
              ><span class="menu-title text-truncate">장애 로그</span></a
            >
          </li>
          <!-- <li class="nav-item">
            <a href="#/ucs/servlog" class="d-flex align-items-center"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="14px"
                height="14px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-circle"
              >
                <circle cx="12" cy="12" r="10"></circle></svg
              ><span class="menu-title text-truncate">이벤트 로그</span
              ></a
            >
          </li> -->
          <li class="nav-item">
            <a href="#/ucs/servall" class="d-flex align-items-center"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="14px"
                height="14px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-circle"
              >
                <circle cx="12" cy="12" r="10"></circle></svg
              ><span class="menu-title text-truncate">서버 목록</span
              ><!----></a
            >
          </li>
        </ul>
      </li>
      <li class="nav-item has-sub open">
        <a href="#" target="_self" class="d-flex align-items-center"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="14px"
            height="14px"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-monitor"
          >
            <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
            <line x1="8" y1="21" x2="16" y2="21"></line>
            <line x1="12" y1="17" x2="12" y2="21"></line></svg
          ><span class="menu-title text-truncate">CMS 관제정보</span
          ><!----></a
        >
        <ul class="menu-content collapse show" id="__BVID__76">
          <li class="nav-item">
            <a href="#/cms/metroom" class="d-flex align-items-center"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="14px"
                height="14px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-circle"
              >
                <circle cx="12" cy="12" r="10"></circle></svg
              ><span class="menu-title text-truncate">미팅룸</span
              ><!----></a
            >
          </li>
          <li class="nav-item">
            <a href="#/cms/calls" class="d-flex align-items-center"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="14px"
                height="14px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-circle"
              >
                <circle cx="12" cy="12" r="10"></circle></svg
              ><span class="menu-title text-truncate">Calls</span
              ><!----></a
            >
          </li>
          <li class="nav-item">
            <a href="#/cms/resv" class="d-flex align-items-center"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="14px"
                height="14px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-circle"
              >
                <circle cx="12" cy="12" r="10"></circle></svg
              ><span class="menu-title text-truncate">미팅 예약</span
              ><!----></a
            >
          </li>
          <li class="nav-item">
            <a href="#/cms/cmslogday" class="d-flex align-items-center"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="14px"
                height="14px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-circle"
              >
                <circle cx="12" cy="12" r="10"></circle></svg
              ><span class="menu-title text-truncate">통계</span
              ><!----></a
            >
          </li>
        </ul>
      </li>
      <li class="nav-item has-sub open">
        <a href="#" target="_self" class="d-flex align-items-center"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="14px"
            height="14px"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-git-pull-request"
          >
            <circle cx="18" cy="18" r="3"></circle>
            <circle cx="6" cy="6" r="3"></circle>
            <path d="M13 6h3a2 2 0 0 1 2 2v7"></path>
            <line x1="6" y1="9" x2="6" y2="21"></line></svg
          ><span class="menu-title text-truncate">CODEC 관제정보</span
          ><!----></a
        >
        <ul class="menu-content collapse show" id="__BVID__89">
          <li class="nav-item">
            <a href="#/vcm/codecsttus" class="d-flex align-items-center"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="14px"
                height="14px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-circle"
              >
                <circle cx="12" cy="12" r="10"></circle></svg
              ><span class="menu-title text-truncate">코덱 실시간 현황</span
              ><!----></a
            >
          </li>
          <!-- <li class="nav-item">
            <a href="#/vcm/codecmng" class="d-flex align-items-center"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="14px"
                height="14px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-circle"
              >
                <circle cx="12" cy="12" r="10"></circle></svg
              ><span class="menu-title text-truncate">코덱 관리</span
              ></a
            >
          </li> -->
        </ul>
      </li>
    </ul>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide("openGroups", ref([]));

    return {
      resolveNavItemComponent,
    };
  },
};
</script>
